<template>
  <div class="index-container no-fullpage">
    <common-header
        :is-show-menu="true"
        :client-width="clientWidth"
        current-menu="about"
        @openMobileMenu="openMobileMenu"
    />
    <!-- <full-page ref="fullpage" :options="options" id="fullpage"> -->
    <div class="section section1">
      <title-introduction :client-width="clientWidth"></title-introduction>
    </div>
    <div class="section section2">
      <about-g-s :client-width="clientWidth"></about-g-s>
    </div>
    <div class="section section3">
      <product-qualification :client-width="clientWidth"></product-qualification>
    </div>
    <div class="section fp-auto-height">
      <common-footer :is-index="true" v-show="clientWidth>mobileWidth"/>
      <mobile-footer v-show="clientWidth<=mobileWidth"/>
    </div>
    <!-- </full-page> -->
    <slide-menu ref="slideMenu"/>
  </div>
</template>

<script>
import CommonHeader from '@components/CommonHeader.vue';
import CommonFooter from '@components/CommonFooter.vue';
import MobileFooter from '@components/MobileFooter.vue';
import TitleIntroduction from './components/TitleIntroduction.vue';
import ProductQualification from './components/ProductQualification.vue';
import AboutGS from './components/AboutGS.vue';
import SlideMenu from '../../components/Menu.vue';

export default {
  name: 'Index',
  components: {
    CommonFooter,
    CommonHeader,
    MobileFooter,
    TitleIntroduction,
    ProductQualification,
    AboutGS,
    SlideMenu,
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  data() {
    return {
      clientWidth: 1920,
      options: {
        // licenseKey: 'YOUR_KEY_HEERE',
        paddingTop: '76px',
        slidesNavigation: true,
        anchors: ['title', 'introduce', 'chara', 'adapt'],
        lockAnchors: true,
      },
    };
  },
  mounted() {
    this.handleResize();
    this.handleScroll();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);

    if (this.$route.query.anchor) {
      this.jumpSection(this.$route.query.anchor);
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleResize() {
      this.clientWidth = document.body.clientWidth;
      this.options.paddingTop = '67px';
    },
    openMobileMenu() {
      this.$refs.slideMenu.onOpen();
    },
    jumpSection(section) {
      this.$refs.fullpage.api.moveTo(section, 0);
    },
    handleScroll() {
      const scrollTop = window.pageYOffset
          || document.documentElement.scrollTop || document.body.scrollTop;
      console.log(scrollTop);
      const element1 = document.querySelector('.section1');
      const element2 = document.querySelector('.section2');
      const element3 = document.querySelector('.section3');

      if (this.clientWidth <= this.mobileWidth) {
        if (scrollTop >= 0 && scrollTop < 400) {
          element1.classList.add('active');
        }
        if (scrollTop >= 400 && scrollTop < 1000) {
          element2.classList.add('active');
        }
        if (scrollTop >= 1000 && scrollTop < 1700) {
          element3.classList.add('active');
        }
      } else {
        if (scrollTop >= 0 && scrollTop < 600) {
          element1.classList.add('active');
        }
        if (scrollTop >= 600 && scrollTop < 1380) {
          element2.classList.add('active');
        }
        if (scrollTop >= 1380 && scrollTop < 2100) {
          element3.classList.add('active');
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.fp-auto-height {
  padding: 0 !important;
}

.section1.active {
  /deep/ .introduct-content {
    .sys-title {
      animation: zoomIn 1s 1;
    }

    .desc {
      animation: fadeIn 1s 1;
    }
  }
}

.section2.active {
  ::v-deep .characteristic-content {
    .title {
      animation: rotateIn 1s 1;

      &::after {
        transition-delay: 1s;
        animation-delay: 1s;
        width: 120px;
        animation-name: example;
        animation-duration: 4s;
      }
    }
  }

  ::v-deep .mobile-characteristic-content {
    .title {
      &::after {
        width: 60px;
      }
    }
  }
}

.section3.active {
  ::v-deep .characteristic-content {
    .title {
      animation: rotateIn 1s 1;

      &::after {
        transition-delay: 1s;
        animation-delay: 1s;
        width: 120px;
        animation-name: example;
        animation-duration: 4s;
      }
    }
  }

  ::v-deep .mobile-characteristic-content {
    .title {
      &::after {
        width: 60px;
      }
    }
  }
}

@keyframes example {
  from {
    width: 0
  }
  to {
    width: 120px
  }
}

.no-fullpage {
  .section {
    height: 100vh;
    min-height: 700px;
  }

  .section3 {
    height: 100%;
  }

  .fp-auto-height {
    height: auto;
    min-height: 0;
  }
}

@import "../../static/fullpage.min.css";
@import "../../less/mobileIndex.less";
</style>
