<template>
  <div :class="[
    'characteristic-content',
    {'mobile-characteristic-content':clientWidth<=mobileWidth}
  ]">
    <div class="title">产品资质</div>
    <div class="img-info">
      <div class="soft-ware img-wapper">
        <img src="../../../assets/imgs/about/cpzz0@2x.png">
        <div>软件著作权</div>
      </div>
      <div class="product-report img-wapper">
        <img src="../../../assets/imgs/about/cpzz1@2x.png">
        <div>产品检测报告</div>
      </div>
      <div class="product-sale img-wapper">
        <img src="../../../assets/imgs/about/cpzz2@2x.png">
        <div>计算机信息系统安全专用产品销售许可证</div>
      </div>
      <div class="password-cert img-wapper">
        <img src="../../../assets/imgs/about/cpzz3@2x.png">
        <div>商用密码认证证书</div>
      </div>
      <div class="password-cert img-wapper">
        <img src="../../../assets/imgs/about/cpzz4@2x.png">
        <div>商用密码认证证书</div>
      </div>
      <!-- 移动端图片顺序 -->
      <template v-if="clientWidth<=mobileWidth">
        <div class="product-adapter img-wapper">
          <img src="../../../assets/imgs/about/cpzz8@2x.png">
          <div>国产化产品适配认证证书</div>
        </div>
        <div class="product-cert img-wapper">
          <img src="../../../assets/imgs/about/cpzz6@2x.png">
          <div>国产化产品适配认证证书</div>
        </div>
        <div class="product-adapter img-wapper">
          <img src="../../../assets/imgs/about/cpzz7@2x.png">
          <div>国产化产品适配认证证书</div>
        </div>
        <div class="adapter-cert img-wapper kuan-img-wapper">
          <img src="../../../assets/imgs/about/cpzz5@2x.png">
          <div>国产化产品适配认证证书</div>
        </div>
      </template>
    </div>
    <template v-if="clientWidth>mobileWidth">
      <!-- pc端图片顺序 -->
      <div class="cert-info">
        <div class="adapter-cert img-wapper">
          <img src="../../../assets/imgs/about/cpzz5@2x.png">
          <div>国产化产品适配认证证书</div>
        </div>
        <div class="product-cert img-wapper">
          <img src="../../../assets/imgs/about/cpzz6@2x.png">
          <div>国产化产品适配认证证书</div>
        </div>
        <div class="product-adapter img-wapper">
          <img src="../../../assets/imgs/about/cpzz7@2x.png">
          <div>国产化产品适配认证证书</div>
        </div>
        <div class="product-adapter img-wapper">
          <img src="../../../assets/imgs/about/cpzz8@2x.png">
          <div>国产化产品适配认证证书</div>
        </div>
      </div>
      <div class="line-us">
        始于此，不止于此，关注我们了解更多...
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ProductQualification',
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
};
</script>

<style lang="less" scoped>
  .characteristic-content{
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 60px 0 74px;
    .title{
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
      position: relative;
      text-align: center;
      margin-bottom: 88px;
      &::after{
        position: absolute;
        content: '';
        width: 0;
        height: 8px;
        background: #F75959;
        border-radius: 4px;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .img-info {
      display: flex;
      width: 1200px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 22px;
      margin: 0 auto;
      .soft-ware {
        img {
          width: 230px;
          height: 300px;
          margin-top: 4px;
        }
        div {
          text-align: center;
          width: 166px;
          margin: 15px auto 0 auto;
        }
      }
      .product-report {
        margin-left: 12px;
        img {
          width: 238px;
          height: 308px;
        }
        div {
          text-align: center;
          width: 166px;
          margin: 11px auto 0 auto;
        }
      }
      .product-sale {
        margin-left: 12px;
        img {
          width: 210px;
          height: 280px;
          margin-top: 4px;
        }
        div {
          text-align: center;
          width: 166px;
          margin: 24px auto 0 auto;
        }
      }
      .password-cert {
        margin-left: 36px;
        img {
          width: 210px;
          height: 280px;
          margin-top: 4px;
        }
        div {
          text-align: center;
          width: 166px;
          margin: 35px auto 0 auto;
        }
      }
    }
    .cert-info {
      margin: 38px auto 0 auto;
      display: flex;
      width: 1200px;
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 22px;
      .adapter-cert {
        img {
          width: 421px;
          height: 280px;
        }
        div {
          text-align: center;
          width: 166px;
          margin: 34px auto 0 auto;
        }
      }
      .product-cert {
        margin-left: 74px;
        img {
          width: 210px;
          height: 280px;
        }
        div {
          text-align: center;
          width: 166px;
          margin: 34px auto 0 auto;
        }
      }
      .product-adapter {
        margin-left: 36px;
        img {
          width: 210px;
          height: 280px;
        }
        div {
          text-align: center;
          width: 166px;
          margin: 34px auto 0 auto;
        }
      }
    }
    .line-us {
      width: 446px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
      text-align: center;
      margin: 30px auto 0 auto;
    }
  }
  @media(max-width:760px){
    .mobile-characteristic-content{
      background: #F3F3F3;
      margin: 0;
      padding: 138px 0;
      .title{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 36px;
        margin-bottom: 16px;
        &::after{
          height: 4px;
          bottom: -5px;
        }
      }
      .img-info,.cert-info{
        width: 100%;
        flex-wrap: wrap;
        padding: 0 59px;
        justify-content: space-between;
        .img-wapper{
          width: 122px;
          // height: 157px;
          margin: 0;
          img{
            max-height: 100%;
            max-width: 100%;
            width: auto;
            height: auto;
          }
          div{
            width: 100%;
            margin: 10px 0 16px 0;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: 17px;
            padding: 0 4px;
            text-align: center;
          }
        }
        .kuan-img-wapper{
          width: 229px;
          display: block;
          margin: 0 auto;
        }
        .product-report{
          div{
            margin: 15px 0 0 0;
          }
        }
      }
    }
  }

  @media(max-width:360px){
    .mobile-characteristic-content{
      .img-info,.cert-info{
        justify-content: center;
      }
    }
  }
</style>
