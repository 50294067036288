<template>
  <div :class="[
    'characteristic-content',
    {'mobile-characteristic-content':clientWidth<=mobileWidth}
  ]">
    <div class="title">关于广烁</div>
    <div class="gs-introduce">
      <p>
        辽宁广烁科技有限公司成立于2015年，注册资金3000万元，是国家级高新技术企业、双软认证企业、
        国家ISO9001质量管理体系认证企业，国家信息技术应用创新工作委员会会员单位、国家电子文件管理推进联盟会员单位、
        辽宁省商用密码协会监事单位、大连软件行业协会会员单位。
      </p>
      <p>
        自2016年起，广烁科技依托集团多年从业经验，研发了广烁印章排版软件、广烁印章管理信息系统、广烁电子签章系统等系列软件产品，承建多个地市级、省级印章管理系统。
      </p>
      <p>
        广烁科技自主研发的电子签章系统，经多年打磨完善，已形成了一套涵盖认证、
        制作、管理、使用、验证的成熟的软、硬件产品，取得多项计算机软件著作权，
        获得计算机信息系统安全专用产品销售许可证、商用密码产品认证证书，
        通过公安部防伪产品质量监督检验中心、国家网络与信息系统安全产品质量监督检验中心、
        公安部计算机信息系统安全产品质量监督检验中心公安部信息安全产品检测中心检测，通过国产化产品适配。
      </p>
    </div>
    <div class="about-info">
      <div class="down-triangle" v-if="clientWidth>mobileWidth"></div>
      <swiper
        :options="swiperOption"
        v-if="showIt"
        class="my-swiper"
      >
        <swiper-slide v-for="(item,index) in swiperData" :key="index">
          <div class="swiper-item-content">
            <div class="img-wapper">
              <img
                :src="require(`../../../assets/imgs/about/${item.imgName}@2x.png`)">
            </div>
            <p class="title-word">{{item.title}}</p>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"  v-if="clientWidth>mobileWidth"></div>
      </swiper>
      <div class="swiper-button-prev" slot="button-prev" v-if="clientWidth>mobileWidth">
        <img src="../../../assets/imgs/icon_left@2x.png">
      </div>
      <div class="swiper-button-next" slot="button-next" v-if="clientWidth>mobileWidth">
        <img src="../../../assets/imgs/icon_right@2x.png">
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
  name: 'AboutGS',
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
  watch: {
    clientWidth(newVal) {
      this.showIt = false;
      this.swiperOption.slidesPerView = newVal <= this.mobileWidth ? 2 : 3;
      setTimeout(() => {
        this.showIt = true;
      }, 1);
    },
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 39,
        autoplay: {
          delay: 2500,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
        },
        allowTouchMove: true,
        // 如果需要前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      swiperData: [
        {
          title: '高新技术企业证书',
          imgName: 'gszz0',
        },
        {
          title: 'ISO9001质量管理体系认证',
          imgName: 'gszz1',
        },
        {
          title: '软件产品证书',
          imgName: 'gszz2',
        },
        {
          title: '软件企业证书',
          imgName: 'gszz3',
        },
        {
          title: '信息技术应用创新工作委员会会员单位',
          imgName: 'gszz4',
        },
        {
          title: '大连软件行业协会会员单位',
          imgName: 'gszz5',
        },
        {
          title: '辽宁省商用密码协会监事单位',
          imgName: 'gszz6',
        },
        {
          title: '电子文件管理推进联盟会员单位',
          imgName: 'gszz7',
        },
      ],
      showIt: true,
    };
  },
};
</script>

<style lang="less" scoped>
  .characteristic-content{
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 80px 0 110px;
    .title{
      font-size: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
      position: relative;
      text-align: center;
      margin-bottom: 95px;
      &::after{
        position: absolute;
        content: '';
        width: 0;
        height: 8px;
        background: #F75959;
        border-radius: 4px;
        bottom: -24px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .gs-introduce {
      width: 1200px;
      font-size: 18px;
      line-height: 36px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
      margin: 0 auto 60px auto;
      p {
        margin: 0;
        text-indent: 40px;
      }
    }
    .about-info {
      height: 500px;
      background-color: #F5F5F5;
      display: flex;
      align-items: center;
      position: relative;
      .down-triangle {
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-top: 15px solid #fff;
        position: absolute;
        top: 0;
        left: 50%;
      }
      .my-swiper{
        width: 1040px;
        height: 340px;
        .swiper-item-content{
          width: 320px;
          text-align: center;
          .img-wapper{
            width: 100%;
            height: 212px;
            img{
              max-width: 100%;
              max-height: 100%;
              width: auto;
              height: auto;
              user-select: none;
            }
          }
          .title-word{
            height: 108px;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            display: flex;
            background: #fff;
            padding: 0 10px;
            align-items: center;
            justify-content: center;
            user-select: none;
          }
        }
      }
    }
    .swiper-pagination-progressbar{
      width: 60px;
      height: 8px;
      background: #ddd;
      border-radius: 4px;
      bottom: 0;
      left: 50%;
      top: auto;
      transform: translateX(-50%);
    }
    ::v-deep.swiper-pagination-progressbar-fill{
      background: #F75959;
      border-radius: 10px;
    }
    .swiper-button-prev,.swiper-button-next{
      top: 40%;
      img{
        width: 44px;
        height: 44px;
      }
      &::after{
        display: none;
      }
    }
    .swiper-button-prev{
      left: 100px;
    }
    .swiper-button-next{
      right: 100px;
    }
  }
  @media(max-width:760px){
    .mobile-characteristic-content{
      margin: 63px auto 0;
      padding: 0 14px;
      .title{
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 36px;
        margin-bottom: 16px;
        &::after{
          // width: 0px;
          height: 4px;
          bottom: -5px;
        }
      }
      .gs-introduce{
        width: 100%;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 16px;
      }
      .about-info{
        background: none;
        height: fit-content;
        .my-swiper{
          width: 100%;
          height: fit-content;
          .swiper-item-content{
            width: 150px;
            .title-word{
              font-size: 14px;
              line-height: 22px;
            }
            .img-wapper{
              width: 150px;
              height: 120px;
            }
          }
        }
      }
    }
  }
</style>
