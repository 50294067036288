<template>
  <div :class="['introduct-content',clientWidth>mobileWidth?'':'mobile-introduct-content']">
    <div class="first-content">
      <img src="../../../assets/imgs/about/title@2x.png"
        class="sys-title"
        v-if="clientWidth>mobileWidth"
      />
     <img src="../../../assets/imgs/mobile/aboutTitle@2x.png"
       class="sys-title mobile-title" v-else />
      <p :class="['desc',clientWidth>mobileWidth?'':'mobile-desc']">
        印章是中华历史长河中特有的文化标记，广烁科技致力于东方印章文化的传承与创新。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BriefDesc',
  data() {
    return {
    };
  },
  props: {
    clientWidth: {
      type: Number,
      default: 1920,
    },
  },
  computed: {
    mobileWidth() {
      return this.$store.state.common.mobileWidth;
    },
  },
};

</script>

<style lang="less" scoped>
  .introduct-content{
    height: 100%;
    background-image: url("../../../assets/imgs/about/bg_head3@2x.png");
    background-size: 100% 100%;
    .first-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      align-items: center;
    }
    .sys-title{
      height: 56px;
      display: block;
    }
    p{
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
      width: 952px;
      margin: 40px auto 0;
      text-align: center;
    }
    .mobile-title{
      height: 27px;
    }
    .mobile-desc{
      width: 275px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px;
      text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    }
  }
  .mobile-introduct-content{
    background-image: url("../../../assets/imgs/mobile/bg_head3@2x.png");
  }
</style>
